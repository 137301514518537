<template>
  <div class="layout">
    <Header :hasSearch="false" :hasShadow="true" />
    <div class="container mt-80 mb-20 pb-20">
      <el-card
        class="mt-20"
        shadow="hover"
        :body-style="{ padding: '20px' }"
        v-loading="loading"
      >
        <div class="d-flex justify-content-center flex-column" slot="header">
          <h1 class="text-black">{{ detail.name }}</h1>
          <div class="block-body-extend">
            <div>
              {{ detail.startTime }}至{{ detail.endTime }}
              <span class="text-info">{{ raceStatus(detail) }}</span>
            </div>
            <div>
              {{ detail.video }}
              <span class="text-gray-6">投稿</span>
            </div>

            <div>
              <template
                v-if="
                  detail.is_close < 1 &&
                  $utils.time() > detail.start_time &&
                  $utils.time() < detail.end_time
                "
              >
                <router-link
                  :to="{
                    path: '/ucenter/publish',
                    query: {
                      race_id: detail.id,
                    },
                  }"
                  target="_blank"
                  >我要投稿</router-link
                >
              </template>
            </div>
          </div>
        </div>
        <img
          v-if="detail.thumb"
          class="w-100"
          style="max-width: 100%"
          :src="viewImg(detail.thumbData.path)"
          alt=""
        />
        <div class="content" v-if="detail" v-html="detail.content"></div>
        <h5>
          参赛视频 <span class="text-gary-6">{{ params.total }}</span>
        </h5>
        <div class="list mt-0">
          <div
            class="list-item"
            v-for="(item, i) in lists"
            :key="'item' + item.id"
          >
            <div class="list-item-body">
              <router-link
                :to="{ path: '/video/detail/' + item.id }"
                target="_blank"
              >
                <img
                  v-if="item.thumb"
                  :src="viewThumb(item.thumbData.thumb, 'medium')"
                  class="thumb"
                />
                <h3>
                  {{ item.title }}
                </h3>
              </router-link>
              <div
                class="
                  list-item-desc
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <NickName v-model="item.user"></NickName>

                <div>{{ $utils.contrastTime(item.create_time) }}</div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center mt-10"
              >
                <div
                  class="pointer d-flex align-items-center"
                  @click="fav(item, i, $event)"
                >
                  <icon type="like" size="14"></icon>
                  <span class="text-gray-6 ml-10 mr-10 small">{{
                    item.fav
                  }}</span>
                </div>
                <div
                  class="pointer d-flex align-items-center"
                  @click="unfav(item, i, $event)"
                >
                  <icon type="unlike" size="14"></icon>
                  <span class="text-gray-6 ml-10 small">{{ item.unfav }}</span>
                </div>

                <div
                  class="pointer d-flex align-items-center"
                  @click="unfav(item, i, $event)"
                >
                  <i class="el-icon-view"></i>
                  <span class="text-gray-6 ml-10 small">{{ item.pv }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-20" v-if="params.pages > 1">
          <el-pagination
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="params.page"
            :page-size="params.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="params.total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Header from "../layout/Header.vue";
export default {
  name: "Detail",
  components: {
    Header,
  },
  data() {
    return {
      loading: false,
      id: 0,
      detail: {},
      metaTitle: "",
      listLoading: false,
      lists: [], //列表
      searchTimer: null, //搜索计时器
      params: {
        //查询参数
        category_id: "",
        tag_id: "",
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.id = this.$route.params.id;

        this.getDetail();
      },
      deep: true,
    },
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  metaInfo() {
    return {
      title: this.metaTitle + "-大赛-" + this.Settings.base.name,
    };
  },
  mounted() {},
  methods: {
    getDetail() {
      this.loading = true;
      this.$api.race.Race.detail({ id: this.id })
        .then((res) => {
          this.loading = false;
          this.detail = res.data;
          this.metaTitle =
            this.detail.name +
            (this.detail.category ? "-" + this.detail.category.name : "");
          this.getList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //取得视频列表
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.listLoading = true;
      this.params.race_id = this.detail.id;
      this.$api.video.Video.lists(this.params)
        .then((res) => {
          this.listLoading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    search(keyword) {
      console.log("keyword:", keyword);
      this.params.keyword = keyword;
      this.getList();
    },
    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },
    //点赞
    fav(item, i, e) {
      e.stopPropagation();
      this.$api.video.Video.fav({
        id: item.id,
      })
        .then((res) => {
          this.lists[i].fav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
      return false;
    },
    //点踩
    unfav(item, i, e) {
      e.stopPropagation();
      this.$api.video.Video.unfav({
        id: item.id,
      })
        .then((res) => {
          this.lists[i].unfav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>